var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900","fullscreen":_vm.$vuetify.breakpoint.xs},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$store.getters['auth/isStudio'])?_c('v-btn',_vm._g({staticStyle:{"height":"30px"},attrs:{"elevation":"0","block":""},domProps:{"textContent":_vm._s(_vm.$t('edit', { name: '' }))},on:{"click":_vm.fetchVacations}},on)):_vm._e(),(_vm.$store.getters['auth/isTattooer'])?_c('v-btn',_vm._g({staticClass:"ml-n1",attrs:{"icon":"","x-small":""},on:{"click":_vm.fetchVacations}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("$eye")])],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"id":"horario"}},[_c('v-card-title',{staticStyle:{"text-decoration":"underline","text-transform":"uppercase"}},[_vm._v(_vm._s(_vm.$t("vacations.title"))+" "+_vm._s(_vm.$store.getters["auth/isTattooer"] ? _vm.$tc("studio") : "")+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[(_vm.$store.getters['auth/isStudio'])?_c('v-col',{staticClass:"py-0",attrs:{"align":"right","cols":"12"}},[_c('FormCloseDays',{ref:"form",on:{"save":_vm.fetchVacations}})],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"footer-props":{
              'items-per-page-text': _vm.$vuetify.breakpoint.smAndDown
                ? ''
                : _vm.$t('items_per_page'),
            },"id":"customer_table","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalDays,"loading":_vm.loading,"sort-by":_vm.sortBy},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"item.reason",fn:function(ref){
            var item = ref.item;
return [(item.reason == null)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(item.reason))])]}},{key:"item.start_date",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$d(new Date(item.start_date), "dateShort")))])]}},{key:"item.end_date",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$d(new Date(item.end_date), "dateShort")))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"cusList",on:{"click":function($event){_vm.$refs.form.id = item.id;
                      _vm.$refs.form.openEdit();}}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"margin-top":"-5px !important"},attrs:{"small":""}},[_vm._v("$edit")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("vacations.edit")))])])],1),_c('v-list-item',{staticClass:"cusList",on:{"click":function($event){return _vm.del(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"margin-top":"-5px !important"},attrs:{"small":""}},[_vm._v("$delete")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("vacations.delete")))])])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }